import { useNavigation, useTheme } from '@react-navigation/native';
import * as Analytics from 'expo-firebase-analytics';
import i18n from 'i18n-js';
import React, { useState } from 'react';
import { ImageBackground, ScrollView, StyleSheet, View } from 'react-native';
import { RadioButtonProps } from 'react-native-radio-buttons-group';

import images from '../assets/images';
import Callout from '../components/Callout';
import Error from '../components/Error';
import Loading from '../components/Loading';
import ModuleCommandRadioGroup from '../components/ModuleCommandRadioGroup';
import ModuleFooter from "../components/ModuleFooter";
import ModuleOverlay from "../components/ModuleOverlay";
import ModuleUnlockButton from "../components/ModuleUnlockButton";
import { ThemedText, ThemedSwitch } from '../components/Themed';
import { styles as sharedLayoutStyles } from "../constants/Layout";
import { useAuth } from '../contexts/AuthContext';
import { useData } from '../contexts/DataContext';
import { ModulesDetailScreenProps, GrowLightsValue } from '../types';


export default function ModulesDetailScreen(props: ModulesDetailScreenProps) {
    // https://reactnavigation.org/docs/typescript/#annotating-usenavigation
    const navigation = useNavigation<ModulesDetailScreenProps['navigation']>();
    const { authState } = useAuth();
    const { modules, isLoading, tasks, performSetGrowLights, performSetWateringEnabled } = useData();
    const { colors } = useTheme();
    const module = modules?.find(x => x.moduleId === props.route.params.moduleId);
    // const [isGrowLightsOn, setIsGrowLightsOn] = useState(module?.growlightsOn);
    const [growLightsRadioButtons, setGrowLightsRadioButtons] = useState<RadioButtonProps[]>([
        {
            id: GrowLightsValue.On, // acts as primary key, should be unique and non-empty string
            containerStyle: styles.radioButtonContainer,
            disabled: !(module && module.online),
            label: i18n.t('MODULEDETAILSCREEN_GROWLIGHTS_ON'),
            labelStyle: styles.radioButtonLabel,
            value: GrowLightsValue.On,
            selected: (module?.growlightsOn && module.growlightsIntensity > 0.2)
        },
        {
            id: GrowLightsValue.Off,
            containerStyle: styles.radioButtonContainer,
            disabled: !(module && module.online),
            label: i18n.t('MODULEDETAILSCREEN_GROWLIGHTS_OFF'),
            labelStyle: styles.radioButtonLabel,
            value: GrowLightsValue.Off,
            selected: !(module?.growlightsOn)
        },
        {
            id: GrowLightsValue.Dim,
            containerStyle: styles.radioButtonContainer,
            disabled: !(module && module.online),
            label: i18n.t('MODULEDETAILSCREEN_GROWLIGHTS_DIM'),
            labelStyle: styles.radioButtonLabel,
            value: GrowLightsValue.Dim,
            selected: (module?.growlightsOn && module.growlightsIntensity < 0.2)
        },
    ]);
    const [isWateringEnabled, setIsWateringEnabled] = useState(module?.wateringEnabled);


    React.useLayoutEffect(() => {
        navigation.setOptions({
            title: module?.fullName,
        });
    }, [navigation, module]);

    if (module && authState?.userToken) {
        const userToken = authState.userToken;

        // function onPressGrowLightsRadioButton(radioButtonsArray: RadioButtonProps[]) {
        const onPressGrowLightsRadioButton = function (radioButtonsArray: RadioButtonProps[]) {
            setGrowLightsRadioButtons(radioButtonsArray);

            const selectedButtonProp = radioButtonsArray.find(x => x.selected);

            // string -> GrowLightsValue
            let value = GrowLightsValue.Off;
            if (selectedButtonProp?.value === GrowLightsValue.On) {
                value = GrowLightsValue.On
            }
            else if (selectedButtonProp?.value === GrowLightsValue.Dim) {
                value = GrowLightsValue.Dim
            }

            performSetGrowLights(userToken, module, value);

            Analytics.logEvent('toggle_growlights_radiogroup', {
                moduleId: module.moduleId,
                value
            });
        }

        // writing as const function to workaround https://github.com/microsoft/TypeScript/issues/36193#issuecomment-827952456
        // const toggleGrowLightsSwitch = async function () {
        //     setIsGrowLightsOn(x => !x)
        //     await performSetGrowLights(userToken, module, !isGrowLightsOn);

        //     Analytics.logEvent('toggle_growlights_switch', {
        //         moduleId: module.moduleId,
        //     });
        // };

        // writing as const function to workaround https://github.com/microsoft/TypeScript/issues/36193#issuecomment-827952456
        const toggleWateringSwitch = async function () {
            setIsWateringEnabled(x => !x)
            await performSetWateringEnabled(userToken, module, !isWateringEnabled);

            Analytics.logEvent('toggle_watering_switch', {
                moduleId: module.moduleId,
            });
        };

        return <ScrollView style={sharedLayoutStyles.rootContainer}
            contentContainerStyle={sharedLayoutStyles.rootContentContainer}>
            <View style={sharedLayoutStyles.mobileContainer}>
                <View style={sharedLayoutStyles.paddedContent}>
                    <View style={styles.headerView}>
                        {module.online
                            ?
                            <ModuleUnlockButton moduleId={module.moduleId} online />
                            :
                            <Callout
                                title={i18n.t('MODULEDETAILSCREEN_OFFLINE_CALLOUT_HEADERTEXT')}
                                body={i18n.t('MODULEDETAILSCREEN_OFFLINE_CALLOUT_TEXT')} />
                        }
                    </View>

                    <ImageBackground source={images.module_rendered}
                        style={styles.imageBackground}
                        imageStyle={{ opacity: module.online ? 1.0 : 0.5 }}
                    >
                        <ModuleOverlay module={module} moduleTasks={(tasks ?? []).filter(t => t.module.moduleId === module.moduleId)} />
                        <View style={sharedLayoutStyles.mobileContainer}>
                            <View style={sharedLayoutStyles.paddedContent}>
                                <ModuleFooter style={styles.moduleFooter} online={module.online} />

                                <View>
                                    <View style={styles.commandRow}>
                                        <ThemedText style={styles.commandText}>{i18n.t('MODULEDETAILSCREEN_GROWLIGHTS_RADIOGROUP')}</ThemedText>
                                        {/*   <ThemedSwitch disabled={!module.online}
                                            onValueChange={async () => await toggleGrowLightsSwitch()}
                                            value={isGrowLightsOn}
                                        /> */}
                                    </View>
                                    <ModuleCommandRadioGroup
                                        containerStyle={styles.radioGroup}
                                        radioButtons={growLightsRadioButtons}
                                        onPress={onPressGrowLightsRadioButton}
                                    />
                                    <View style={[styles.commandDivider, { borderColor: colors.border }]} />
                                    <View style={styles.commandRow}>
                                        <ThemedText style={styles.commandText}>{i18n.t('MODULEDETAILSCREEN_WATERING_SWITCH')}</ThemedText>
                                        <ThemedSwitch disabled={!module.online}
                                            onValueChange={async () => await toggleWateringSwitch()}
                                            value={isWateringEnabled}
                                        />
                                    </View>
                                </View>
                            </View>
                        </View>
                    </ImageBackground>
                    <View style={styles.bottomSpacer} />
                </View>
            </View >
        </ScrollView>;
    }
    else {
        return isLoading ? <Loading /> : <Error />;
    }
}


const styles = StyleSheet.create({
    headerView: {
        marginTop: 8,
        marginBottom: 16
    },
    imageBackground: {
        width: 960 / 2,
        height: 1068 / 2,
        marginLeft: -((960 / 2 - 320) / 2 + 8),
        alignItems: 'center',

        // borderWidth: 1,
        // borderColor: 'blue'
    },
    moduleFooter: {
        marginBottom: 8,

        // borderWidth: 1,
        // borderColor: 'green'
    },
    commandRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 36,
    },
    commandText: {
        fontSize: 16,
        fontWeight: '500'
    },
    commandDivider: {
        borderBottomWidth: 1,
        marginTop: 16,
        marginBottom: 16
    },
    radioGroup: {
        alignItems: 'stretch',
    },
    radioButtonContainer: {
        alignItems: 'center',
        justifyContent: 'space-between',
        marginHorizontal: 10,
        marginVertical: 5,
    },
    radioButtonLabel: {
        fontSize: 16,
        fontWeight: '400'
    },
    bottomSpacer: {
        // height: 64,
    }
});
